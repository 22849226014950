import { OrdersRatingModel } from './OrdersRatingModel'
import { OrderSourceModel } from './OrderSourceModel'

export class OrdersInfoModel {
  delivered: number | null
  completed: number | null
  completedAmountPrice: number | null
  waitingCustomerAccept: number | null
  waitingFirmAccept: number | null
  cancelled: number | null
  rejected: number | null
  packaged: number | null
  waitingPackaging: number | null
  failed: number | null
  notGivenOrderTime: number | null
  acceptNotPreferableOrderTime: number | null
  orderSource: OrderSourceModel | null
  ordersRating: OrdersRatingModel | null

  constructor(data: Partial<OrdersInfoModel>) {
    this.delivered = data?.delivered || 0
    this.completed = data?.completed || 0
    this.completedAmountPrice = data?.completedAmountPrice || 0
    this.waitingCustomerAccept = data?.waitingCustomerAccept || 0
    this.waitingFirmAccept = data?.waitingFirmAccept || 0
    this.cancelled = data?.cancelled || 0
    this.rejected = data?.rejected || 0
    this.packaged = data?.packaged || 0
    this.waitingPackaging = data?.waitingPackaging || 0
    this.failed = data?.failed || 0
    this.notGivenOrderTime = data?.notGivenOrderTime || 0
    this.acceptNotPreferableOrderTime = data?.acceptNotPreferableOrderTime || 0
    this.orderSource = data?.orderSource ? new OrderSourceModel(data?.orderSource) : null
    this.ordersRating = data?.ordersRating ? new OrdersRatingModel(data?.ordersRating) : null
  }

  toMap() {
    return {
      delivered: this.delivered,
      completed: this.completed,
      completedAmountPrice: this.completedAmountPrice,
      packaged: this.packaged,
      waitingPackaging: this.waitingPackaging,
      waitingCustomerAccept: this.waitingCustomerAccept,
      waitingFirmAccept: this.waitingFirmAccept,
      rejected: this.rejected,
      cancelled: this.cancelled,
      failed: this.failed,
      ...this.orderSource?.toMap(),
      ...this.ordersRating?.toMap(),
    }
  }

  toMapHome() {
    return {
      ...this.toMap(),
      notGivenOrderTime: this.notGivenOrderTime,

    }
  }

  toMapOffice() {
    return {
      ...this.toMap(),
      acceptNotPreferableOrderTime: this.acceptNotPreferableOrderTime,
    }
  }
}

export const mapOrdersStatistic = data => new OrdersInfoModel(data)
