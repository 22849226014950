import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
import type { DocumentReference } from 'firebase/firestore'
import { getDocs, query, serverTimestamp, updateDoc, where, writeBatch } from 'firebase/firestore'
import { getDownloadURL, listAll, ref as storageRef, uploadBytes } from 'firebase/storage'
import { errorDefault, savedDefault } from '../helpers/snackbar'
import { collectionOwners } from '../firestoreWrappers'
import { firestore, storage } from '../firebaseCore'
import type { CompanyModel } from '../models/CompanyModel'
import { mapCompany } from '../models/CompanyModel'
import { mapOwners } from '../models/ManagerModel'
import { collectionCompanies } from '../firebase/firestoreCompanies'
import store from '.'
import type { AgreementModel } from '~/models/AgreementModel'
import type { OwnerModel } from '~/models/OwnerModel'

const mapPromises = item => getDownloadURL(item)
function mapFunc(data) {
  return (item, index) => ({
    name: item.name,
    url: data[index],
  })
}

function mapByOwner(owner) {
  return (ownerData) => {
    if (ownerData.reference.id === owner.reference.id)
      ownerData.loading = true
    return ownerData
  }
}

function mapByOwner2(owner) {
  return (ownerData) => {
    if (ownerData.reference.id === owner.reference.id) {
      ownerData.isOwner = !owner?.isOwner
      delete ownerData.loading
    }
    return ownerData
  }
}

function mapByOwner3(owner) {
  return (ownerData) => {
    if (ownerData.reference.id === owner.reference.id)
      delete ownerData.loading
    return ownerData
  }
}

function mapByCompany(company, agreement) {
  return (companyData) => {
    if (companyData.reference.id === company.reference.id)
      companyData.agreement = agreement
    return companyData
  }
}

export const useCompaniesStore = defineStore('companies', () => {
  const companies: Ref<CompanyModel[] | null> = ref(null)
  const agreements: Ref<AgreementModel[] | null> = ref(null)
  const loadingAgreements: Ref<boolean> = ref(false)
  const errorAgreements: Ref<unknown | null> = ref(null)
  const owners: Ref<OwnerModel[] | null> = ref(null)
  const loadingOwners: Ref<boolean> = ref(false)
  const errorOwners: Ref<unknown | null> = ref(null)

  const resetState = () => {
    companies.value = null
    agreements.value = null
    loadingAgreements.value = false
    errorAgreements.value = null
    owners.value = null
    loadingOwners.value = false
    errorOwners.value = null
  }

  const getCompanies = () => {
    store.dispatch('shared/init')

    const onSuccess = ([{ docs }]) => {
      companies.value = docs.map(mapCompany)
      store.dispatch('shared/success')
    }

    const onError = (error) => {
      console.error(error)
      store.dispatch('shared/error', error)
      store.dispatch('snackbar/showSnackbar', errorDefault(error))
    }

    return Promise.all([getDocs(collectionCompanies)])
      .then(onSuccess)
      .catch(onError)
  }

  const getOwners = (companyRef: DocumentReference) => {
    loadingOwners.value = true
    errorOwners.value = null

    const onSuccess = (arg0) => {
      loadingOwners.value = false
      owners.value = arg0.docs.map(mapOwners)
    }

    const onError = (error) => {
      console.error(error)
      loadingOwners.value = false
      errorOwners.value = error
      store.dispatch('snackbar/showSnackbar', errorDefault(error))
    }

    return getDocs(query(collectionOwners, where('company', '==', companyRef)))
      .then(onSuccess)
      .catch(onError)
  }

  const getAgreements = (companyRef: DocumentReference) => {
    loadingAgreements.value = true
    errorAgreements.value = null

    const onError = (error) => {
      console.error(error)
      loadingAgreements.value = false
      errorAgreements.value = error
      store.dispatch('snackbar/showSnackbar', errorDefault(error))
    }

    const onSuccess = ({ items }) => {
      Promise.all(items.map(mapPromises))
        .then((data) => {
          loadingAgreements.value = false
          agreements.value = items.map(mapFunc(data))
        })
        .catch(onError)
    }

    const path = `${companyRef.id}/docs/`

    return listAll(storageRef(storage, path))
      .then(onSuccess)
      .catch(onError)
  }

  const updateOwnerStatus = (owner, userData) => {
    const newOwnerData = {
      isOwner: !owner?.isOwner,
      lastUpdateByUser: userData.reference,
      lastUpdateTime: serverTimestamp(),
    }

    const byId = ({ reference }) => reference.id === owner.company.id
    // @ts-expect-error todo
    const [ownerCompany] = companies.value.filter(byId)
    const acceptedByObject = ownerCompany?.agreement?.acceptedBy || {}

    if (!owner?.isOwner)
      acceptedByObject[owner.reference.id] = false
    else
      delete acceptedByObject[owner.reference.id]

    const newCompanyAgreement = {
      agreement: {
        ...ownerCompany?.agreement || {},
        acceptedBy: acceptedByObject,
      },
    }

    owners.value = owners.value?.map(mapByOwner(owner)) || null

    const onError = (error) => {
      console.error(error)
      owners.value = owners.value?.map(mapByOwner3(owner)) || null
      store.dispatch('snackbar/showSnackbar', errorDefault(error))
    }

    const onSuccess = () => {
      owners.value = owners.value?.map(mapByOwner2(owner)) || null
      companies.value = companies.value?.map(mapByCompany(ownerCompany, newCompanyAgreement.agreement)) || null
    }

    const newCompanyAgreementData = {
      ...newCompanyAgreement,
      lastUpdateByUser: userData.reference,
      lastUpdateTime: serverTimestamp(),
    }

    const batch = writeBatch(firestore)

    return batch
      .update(owner.reference, newOwnerData)
      // @ts-expect-error todo
      .update(ownerCompany.reference, newCompanyAgreementData)
      .commit()
      .then(onSuccess)
      .catch(onError)
  }

  const uploadAgreement = (selectedFile, company) => {
    const activeFile = `${new Date().toISOString()}${selectedFile.name}`
    const path = `${company.reference.id}/docs/${activeFile}`

    const onSuccess = (result) => {
      getDownloadURL(result.ref).then((url) => {
        const agreement = {
          name: result.metadata.name,
          url,
        }
        // @ts-expect-error todo
        agreements.value = [...agreements.value || [], agreement]
      })
    }

    const onError = (error) => {
      console.error(error)
      store.dispatch('snackbar/showSnackbar', errorDefault(error))
    }

    return uploadBytes(storageRef(storage, path), selectedFile)
      .then(onSuccess)
      .catch(onError)
  }

  const updateCompanyAgreement = (agreement, company, userData) => {
    const onSuccess = () => {
      companies.value = companies.value?.map(mapByCompany(company, {
        ...company?.agreement || {},
        ...agreement,
      })) || null
      store.dispatch('snackbar/showSnackbar', savedDefault)
    }

    const onError = (error) => {
      console.error(error)
      store.dispatch('snackbar/showSnackbar', errorDefault(error))
    }

    return updateDoc(company.reference, {
      agreement: {
        ...company?.agreement || {},
        ...agreement,
      },
      lastUpdateByUser: userData.reference,
      lastUpdateTime: serverTimestamp(),
    })
      .then(onSuccess)
      .catch(onError)
  }

  return {
    companies,
    agreements,
    loadingAgreements,
    errorAgreements,
    owners,
    loadingOwners,
    errorOwners,
    resetState,
    getCompanies,
    getOwners,
    getAgreements,
    updateOwnerStatus,
    uploadAgreement,
    updateCompanyAgreement,
  }
})
