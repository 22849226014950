import { getCurrentInstance } from 'vue'

export function useVuex() {
  // @ts-expect-error getCurrentInstance
  const { $root } = getCurrentInstance().proxy
  const { $store } = $root

  return {
    $store,
  }
}
