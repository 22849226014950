export class OrderSourceModel {
  mobile: number | null
  web: number | null
  companyOwnWeb: number | null

  constructor(data: Partial<OrderSourceModel>) {
    this.mobile = data.mobile || 0
    this.web = data.web || 0
    this.companyOwnWeb = data.companyOwnWeb || 0
  }

  toMap() {
    return {
      mobile: this.mobile,
      web: this.web,
      companyOwnWeb: this.companyOwnWeb,
    }
  }
}

export const mapOrdersStatistic = data => new OrderSourceModel(data)
