<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { mdiClose } from '@mdi/js'
import { useVuetify } from '../../useVuetify'

const details = ref(false)

const { isPhone } = useVuetify()

const showDownload = ref(false)

function closeDownload() {
  showDownload.value = false
  localStorage.download = JSON.stringify(true)
}

const showCookies = ref(false)

function closeCookies() {
  showCookies.value = false
  showDownload.value = true
  localStorage.cookies = JSON.stringify(true)
}

function closeCookiesAndRedirectDownload() {
  showDownload.value = false
  window.location.href = 'https://gooddystrybucja.pl/LandingPageMobile'
}

onMounted(() => {
  showCookies.value = !(
    localStorage?.cookies && JSON.parse(localStorage?.cookies)
  )
  showDownload.value
    = localStorage?.download && !JSON.parse(localStorage?.download)
})
</script>

<template>
  <div>
    <v-snackbar
      v-if="isPhone"
      color="black"
      tile
      :timeout="-1"
      :value="showDownload"
      width="100%"
      @click="closeDownload"
    >
      <v-row
        align="center"
        class="justify-center"
      >
        <v-btn
          class="py-1"
          icon
          @click="closeDownload"
        >
          <v-icon>
            {{ mdiClose }}
          </v-icon>
        </v-btn>

        <span class="ma-4">{{ $t("DownloadBanner.title") }}</span>

        <v-btn
          class="py-1 v-btn"
          color="#75C402"
          rel="noopener noreferrer"
          target="_blank"
          @click="closeCookiesAndRedirectDownload"
        >
          {{ $t("DownloadBanner.btn") }}
        </v-btn>
      </v-row>
    </v-snackbar>

    <v-snackbar
      color="black"
      :timeout="-1"
      :value="showCookies"
    >
      <v-row class="justify-center">
        <span class="ma-2">{{ $t("cookies.title") }}</span>

        <span class="ma-2">
          {{ $t("cookies.description") }}

          <a
            class="v-btn"
            color="primary"
            href="/cookies"
            text
          >
            {{ $t("cookies.policy") }}
          </a>
        </span>
      </v-row>

      <div
        v-if="details"
        class="mb-2"
      >
        <v-row
          dense
          justify="space-between"
        >
          <span class="pt-5">
            {{ $t("cookies.functional") }}
          </span>
        </v-row>

        <br>

        <span>
          {{ $t("cookies.functionalD") }}
        </span>

        <br>

        <span>
          {{ $t("cookies.functionalD2") }}
        </span>
      </div>

      <v-row class="justify-center py-3">
        <v-btn
          v-if="details"
          class="mx-1 my-1 v-btn"
          color="#75C402"
          @click="closeCookies"
        >
          {{ $t("cookies.save") }}
        </v-btn>

        <v-btn
          v-if="!details"
          key="v-btn-2"
          class="mx-1 my-1 v-btn"
          color="#75C402"
          @click="closeCookies"
        >
          {{ $t("cookies.accept") }}
        </v-btn>

        <v-btn
          class="mx-1 my-1 v-btn"
          text
          @click="closeCookies"
        >
          {{ $t("cookies.reject") }}
        </v-btn>

        <v-btn
          v-if="!details"
          key="v-btn-3"
          class="mx-1 my-1 v-btn"
          text
          @click="details = true"
        >
          {{ $t("cookies.settings") }}
        </v-btn>
      </v-row>
    </v-snackbar>
  </div>
</template>

<style scoped>
.v-btn {
  text-transform: none !important;
}

a {
  text-decoration: none;
}
</style>
