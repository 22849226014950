import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { ref } from 'vue'
import type { DocumentReference, Unsubscribe } from 'firebase/firestore'
import { addDoc, onSnapshot, serverTimestamp, updateDoc } from 'firebase/firestore'
import {
  getReportBugOrSuggestionActive,
  getReportBugOrSuggestionActiveByCitiesActive,
  getReportBugOrSuggestionActiveByCitiesSolved,
  getReportBugOrSuggestionActiveByCitiesWaitingCustomerAnswer,
  getReportWithoutCities,
  reportBugOrSuggestionCollection,
} from '../firestoreWrappers'
import { isManagerOrDeliver, isOwner } from '../helpers/roles'
import { errorDefault, savedDefault } from '../helpers/snackbar'
import type { ReportBugOrSuggestionModel } from '../models/ReportBugOrSuggestionModel'
import { mapReportBugOrSuggestion } from '../models/ReportBugOrSuggestionModel'
import store from '.'

export const useReportBugOrSuggestionStore = defineStore('reportBugOrSuggestion', () => {
  const reportBugOrSuggestion: Ref<ReportBugOrSuggestionModel[] | null> = ref(null)
  const reportBugOrSuggestionSolved: Ref<ReportBugOrSuggestionModel[] | null> = ref(null)
  const reportBugOrSuggestionWaitingCustomerAnswer: Ref<ReportBugOrSuggestionModel[] | null> = ref(null)
  const reportBugOrSuggestionActive: Ref<ReportBugOrSuggestionModel[] | null> = ref(null)
  const reportBugOrSuggestionWithoutCities: Ref<ReportBugOrSuggestionModel[] | null> = ref(null)
  const unsubscribe: Ref<Unsubscribe | null> = ref(null)
  const unsubscribe2: Ref<Unsubscribe | null> = ref(null)
  const unsubscribe3: Ref<Unsubscribe | null> = ref(null)
  const unsubscribe4: Ref<Unsubscribe | null> = ref(null)
  const reportBugOrSuggestionLoading: Ref<boolean> = ref(false)
  const reportBugOrSuggestionSuccess: Ref<boolean> = ref(false)
  const reportBugOrSuggestionError: Ref<unknown | null> = ref(null)

  const resetState = () => {
    reportBugOrSuggestion.value = null
    reportBugOrSuggestionSolved.value = null
    reportBugOrSuggestionWaitingCustomerAnswer.value = null
    reportBugOrSuggestionActive.value = null
    reportBugOrSuggestionWithoutCities.value = null
    unsubscribe.value = null
    unsubscribe2.value = null
    unsubscribe3.value = null
    unsubscribe4.value = null
    reportBugOrSuggestionLoading.value = false
    reportBugOrSuggestionSuccess.value = false
    reportBugOrSuggestionError.value = null
  }

  const getReportBugOrSuggestion = (company: DocumentReference, cities: string[], displayName: string) => {
    if (!company)
      return

    reportBugOrSuggestionLoading.value = true
    if (unsubscribe.value) {
      unsubscribe.value()
      unsubscribe.value = null
    }
    if (unsubscribe2.value || unsubscribe3.value) {
      // @ts-expect-error todo
      unsubscribe.value()
      // @ts-expect-error todo
      unsubscribe2.value()
      // @ts-expect-error todo
      unsubscribe3.value()
      // @ts-expect-error todo
      unsubscribe4.value()
      unsubscribe2.value = null
      unsubscribe3.value = null
      unsubscribe4.value = null
    }

    const onSuccess = (arg0) => {
      reportBugOrSuggestion.value = arg0.docs.map(mapReportBugOrSuggestion)
      reportBugOrSuggestionLoading.value = false
    }

    const onError = (error) => {
      console.error(error)
      reportBugOrSuggestionError.value = error
      reportBugOrSuggestionLoading.value = false
      store.dispatch('snackbar/showSnackbar', errorDefault(error))
    }

    const onSuccess4 = key => (arg0) => {
      reportBugOrSuggestionLoading.value = false
      reportBugOrSuggestionError.value = null
      if (key === 'reportBugOrSuggestionActive') {
        reportBugOrSuggestionActive.value = arg0.docs.map(mapReportBugOrSuggestion)
      }
      else if (key === 'reportBugOrSuggestionSolved') {
        reportBugOrSuggestionSolved.value = arg0.docs.map(mapReportBugOrSuggestion)
      }
      else if (key === 'reportBugOrSuggestionWaitingCustomerAnswer') {
        reportBugOrSuggestionWaitingCustomerAnswer.value = arg0.docs.map(mapReportBugOrSuggestion)
      }
      else if (key === 'reportBugOrSuggestionWithoutCities') {
        reportBugOrSuggestionWithoutCities.value = arg0.docs.map(mapReportBugOrSuggestion)
      }
    }

    if (isOwner(displayName) || isManagerOrDeliver(displayName)) {
      if (isOwner(displayName)) {
        unsubscribe.value = onSnapshot(getReportBugOrSuggestionActive(company), onSuccess, onError)
      }
      else {
        unsubscribe.value = onSnapshot(getReportBugOrSuggestionActiveByCitiesActive(company, cities), onSuccess4('reportBugOrSuggestionActive'), onError)
        unsubscribe2.value = onSnapshot(getReportBugOrSuggestionActiveByCitiesSolved(company, cities), onSuccess4('reportBugOrSuggestionSolved'), onError)
        unsubscribe3.value = onSnapshot(getReportWithoutCities(company), onSuccess4('reportBugOrSuggestionWithoutCities'), onError)
        unsubscribe4.value = onSnapshot(getReportBugOrSuggestionActiveByCitiesWaitingCustomerAnswer(company, cities), onSuccess4('reportBugOrSuggestionWaitingCustomerAnswer'), onError)
      }
    }
    else {
      onError('Wrong site')
    }
  }

  const updateReportBugOrSuggestion = (userDataRef: DocumentReference, item: ReportBugOrSuggestionModel, status: string) => {
    reportBugOrSuggestionLoading.value = true

    updateDoc(item.reference as DocumentReference, {
      status,
      lastUpdateByUser: userDataRef,
      lastUpdateTime: serverTimestamp(),
    })
      .then(() => {
        reportBugOrSuggestionLoading.value = false
        reportBugOrSuggestionError.value = null
      })
      .catch((error) => {
        console.error(error)
        reportBugOrSuggestionError.value = error
        reportBugOrSuggestionLoading.value = false
        store.dispatch('snackbar/showSnackbar', errorDefault(error))
      })
  }

  const sendCompanySuggestion = (data: ReportBugOrSuggestionModel) => {
    reportBugOrSuggestionLoading.value = true

    addDoc(reportBugOrSuggestionCollection, data.toMap())
      .then(() => {
        reportBugOrSuggestionLoading.value = false
        reportBugOrSuggestionError.value = null
        store.dispatch('snackbar/showSnackbar', savedDefault)
      })
      .catch((error) => {
        console.error(error)
        reportBugOrSuggestionError.value = error
        reportBugOrSuggestionLoading.value = false
        store.dispatch('snackbar/showSnackbar', errorDefault(error))
      })
  }

  return {
    reportBugOrSuggestion,
    reportBugOrSuggestionSolved,
    reportBugOrSuggestionWaitingCustomerAnswer,
    reportBugOrSuggestionActive,
    reportBugOrSuggestionWithoutCities,
    unsubscribe,
    unsubscribe2,
    unsubscribe3,
    unsubscribe4,
    reportBugOrSuggestionLoading,
    reportBugOrSuggestionSuccess,
    reportBugOrSuggestionError,
    resetState,
    getReportBugOrSuggestion,
    updateReportBugOrSuggestion,
    sendCompanySuggestion,
  }
})
