import type { DocumentData, DocumentReference, FieldValue } from 'firebase/firestore'

export class DocumentModel {
  createdByUser: DocumentReference | null
  lastUpdateByUser: DocumentReference | null
  lastUpdateTime: FieldValue | null
  reference: DocumentReference | null

  constructor(data: Partial<DocumentModel>, reference?: DocumentReference | null) {
    this.createdByUser = data.createdByUser || null
    this.lastUpdateByUser = data.lastUpdateByUser || null
    this.lastUpdateTime = data.lastUpdateTime || null
    this.reference = reference || null
  }

  toMap() {
    return {
      lastUpdateTime: this.lastUpdateTime || null,
      lastUpdateByUser: this.lastUpdateByUser || null,
    }
  }

  toMapCreate() {
    return {
      createdByUser: this.createdByUser || null,
      lastUpdateTime: this.lastUpdateTime || null,
      lastUpdateByUser: this.lastUpdateByUser || null,
    }
  }
}

export function mapData(Type, data: DocumentData) {
  return new Type(
    data.data(),
    data.ref,
  )
}
