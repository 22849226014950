import type { DocumentData, DocumentReference } from 'firebase/firestore'
import { mapDateFromFirebaseFormat } from '../helpers/time'

export class MissingCompanyModel {
  date: string | null
  company: DocumentReference | null
  city: string | null
  address: string | null
  phone: string | null
  postCode: string | null
  message: string | null
  companyRef: string | null
  status: string | null
  reference: DocumentReference | null

  constructor(data: Partial<MissingCompanyModel>, reference?: DocumentReference) {
    this.date = mapDateFromFirebaseFormat(data.date) || null
    this.company = data.company || null
    this.city = data.city || null
    this.address = data.address || null
    this.phone = data.phone || null
    this.postCode = data.postCode || null
    this.message = data.message || null
    this.companyRef = data.companyRef || null
    this.status = data.status || null
    this.reference = reference || null
  }

  toMap() {
    return {
      date: this.date,
      company: this.company,
      city: this.city,
      address: this.address,
      phone: this.phone,
      postCode: this.postCode,
      message: this.message?.trim() || null,
      companyRef: this.companyRef?.trim() || null,
      status: this.status,
    }
  }
}

export function mapMissingCompany(message: DocumentData) {
  return new MissingCompanyModel(
    message.data(),
    message.ref,
  )
}
