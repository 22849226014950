import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { ref } from 'vue'
import type { DocumentData, DocumentReference } from 'firebase/firestore'
import { addDoc, deleteDoc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { errorDefault, savedDefault } from '../helpers/snackbar'
import type { CouponModel } from '../models/CouponModel'
import { mapCoupons } from '../models/CouponModel'
import { checkCouponCode, collectionCoupons, getCouponsCollection, getNextCouponsCollection } from '../firebase/firestoreCoupons'
import store from '.'

function onErrorShared(error: unknown) {
  console.error(error)
  store.dispatch(
    'snackbar/showSnackbar',
    errorDefault(error),
  )
}

export function generateRandomText() {
  const charset = 'abcdefghijklmnopqrstuvwxyz0123456789'
  const length = 6
  let retVal = ''
  for (let charsNumber = charset.length, index = 0; index < length; ++index)
    retVal += charset.charAt(Math.round(Math.random() * charsNumber))
  return retVal
}

async function checkCode(promoCode: string) {
  const response = await checkCouponCode(promoCode)
  return response.empty
}

export const useCouponsStore = defineStore('coupons', () => {
  const data: Ref<CouponModel[] | null> = ref(null)
  const generatedData: Ref<string[] | null> = ref(null)
  const size: Ref<number | null> = ref(null)
  const lastVisible: Ref<DocumentData | null> = ref(null)
  const loading: Ref<boolean> = ref(false)
  const error: Ref<unknown | null> = ref(null)

  const resetState = () => {
    data.value = null
    generatedData.value = null
    size.value = null
    lastVisible.value = null
    loading.value = false
    error.value = null
    loading.value = false
    error.value = null
  }

  const getCoupons = (userDataCompany: DocumentReference) => {
    loading.value = true
    error.value = null

    const promise = lastVisible.value
      ? getNextCouponsCollection(userDataCompany, lastVisible.value)
      : getCouponsCollection(userDataCompany)

    promise
      .then((arg0: DocumentData) => {
        size.value = arg0.size
        lastVisible.value = arg0.docs[arg0.docs.length - 1]
        loading.value = false
        error.value = null
        data.value = data.value
          ? [...data.value, ...arg0.docs.map(mapCoupons)]
          : arg0.docs.map(mapCoupons)
      })
      .catch((errorArg) => {
        loading.value = false
        error.value = errorArg
        onErrorShared(errorArg)
      })
  }

  const createCoupon = async (dataArg: CouponModel, userDataRef: DocumentReference) => {
    loading.value = true
    error.value = null

    let newCouponRef: DocumentReference | null = null

    dataArg.lastUpdateByUser = userDataRef
    dataArg.lastUpdateTime = serverTimestamp()

    const generateCode = async () => {
      let code = ''
      let empty = false
      do {
        code = generateRandomText()
        empty = await checkCode(code)
      } while (!empty)
      return code
    }

    const code = await generateCode()
    dataArg.promoCode = code

    addDoc(collectionCoupons, {
      ...dataArg.toMap(),
      createdByUser: userDataRef,
    })
      .then((docRef) => {
        newCouponRef = docRef
        dataArg.reference = newCouponRef
        data.value = data.value
          ? [...data.value, dataArg]
          : [dataArg]
        loading.value = false
        error.value = null
        store.dispatch('snackbar/showSnackbar', savedDefault)
      })
      .catch((errorArg) => {
        if (newCouponRef)
          deleteDoc(newCouponRef)
        loading.value = false
        error.value = errorArg
        onErrorShared(errorArg)
      })
  }

  const createCoupons = (dataArg: CouponModel, count: number, userDataRef: DocumentReference) => {
    loading.value = true
    error.value = null

    // @ts-expect-error todo
    const dataCopy: CouponModel = { ...dataArg }
    dataCopy.lastUpdateByUser = userDataRef
    dataCopy.lastUpdateTime = serverTimestamp()

    const codes: string [] = []
    const newData: CouponModel[] = []

    const generateCode = async () => {
      let code = ''
      let empty = false
      do {
        code = generateRandomText()
        empty = await checkCode(code)
      } while (!empty)
      return code
    }

    const promises = Array.from({ length: count }, async () => {
      const code = await generateCode()
      codes.push(code)
      dataCopy.promoCode = code

      return addDoc(collectionCoupons, {
        ...dataCopy.toMap(),
        createdByUser: userDataRef,
      }).then((docRef) => {
        const copyData = { ...dataCopy }
        copyData.reference = docRef
        // @ts-expect-error todo
        newData.push(copyData)
      })
    })

    Promise.all(promises)
      .then(() => {
        data.value = data.value
          ? [...data.value, ...newData]
          : newData
        generatedData.value = codes
        loading.value = false
        error.value = null
        store.dispatch('snackbar/showSnackbar', savedDefault)
      })
      .catch((errorArg) => {
        loading.value = false
        error.value = errorArg
        onErrorShared(errorArg)
      })
  }

  const editCoupon = (newData: CouponModel, userDataRef: DocumentReference) => {
    loading.value = true
    error.value = null

    newData.lastUpdateByUser = userDataRef
    newData.lastUpdateTime = serverTimestamp()

    updateDoc(userDataRef, newData.toMap())
      .then(() => {
        const findByReference = (item: CouponModel) => item?.reference?.id == userDataRef.id
        const couponIndex = data.value?.findIndex(findByReference)
        // @ts-expect-error todo
        const newCoupon = [...data.value]
        // @ts-expect-error todo
        newCoupon[couponIndex] = newData
        data.value = newCoupon
        loading.value = false
        error.value = null
        store.dispatch('snackbar/showSnackbar', savedDefault)
      })
      .catch((errorArg) => {
        loading.value = false
        error.value = errorArg
        onErrorShared(errorArg)
      })
  }

  return {
    data,
    generatedData,
    size,
    lastVisible,
    loading,
    error,
    resetState,
    getCoupons,
    createCoupon,
    createCoupons,
    editCoupon,
  }
})
