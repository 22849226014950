import { collection, getDocs, onSnapshot, query, serverTimestamp, Timestamp, updateDoc, where } from 'firebase/firestore'
import { firestore } from '../firebaseCore'

export const deliversSellsCollection = collection(
  firestore,
  'deliversSells',
)

export function getDeliversSellsQuery(company, date, onSuccess, onError) {
  return onSnapshot(
    query(
      deliversSellsCollection,
      where(
        'company',
        '==',
        company,
      ),

      where(
        'date',
        '==',
        date,
      ),

    ),
    onSuccess,
    onError,
  )
}
export async function getDeliversSellsLast2Months(company, route) {
  const dateLimit = new Date()
  dateLimit.setDate(dateLimit.getDate() - 62)

  return await getDocs(query(
    deliversSellsCollection,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'route',
      '==',
      route,
    ),
    where(
      'date',
      '>',
      Timestamp.fromDate(dateLimit),
    ),
  ))
}
export function getDeliversSellsQueryMulti(company, dateFrom, dateTo, onSuccess, onError) {
  return onSnapshot(
    query(
      deliversSellsCollection,
      where(
        'company',
        '==',
        company,
      ),
      where(
        'date',
        '>=',
        dateFrom,
      ),
      where(
        'date',
        '<=',
        dateTo,
      ),

    ),
    onSuccess,
    onError,
  )
}

export function updateStatus(item, status, userData) {
  return updateDoc(
    item.reference,
    {
      isDisabled: status,
      lastUpdateByUser: userData.reference,
      lastUpdateTime: serverTimestamp(),
    },
  )
}
