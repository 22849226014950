export class RatingModel {
  notFollowRulesCount: number | null
  punctualDelivery: number | null
  notPunctualDelivery: number | null
  quantity: number | null
  score: number | null

  constructor(data: Partial<RatingModel>) {
    this.notFollowRulesCount = data.notFollowRulesCount || 0
    this.punctualDelivery = data.punctualDelivery || 0
    this.notPunctualDelivery = data.notPunctualDelivery || 0
    this.quantity = data.quantity || 0
    this.score = data.score || 0
  }

  toMap() {
    return {
      notFollowRulesCount: this.notFollowRulesCount,
      punctualDelivery: this.punctualDelivery,
      notPunctualDelivery: this.notPunctualDelivery,
      quantity: this.quantity,
      score: this.score,
    }
  }
}
