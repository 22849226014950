import type { DocumentReference, Timestamp } from 'firebase/firestore'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { firestore } from '../firebaseCore'

export const collectionPromoCodeStatistics = collection(
  firestore,
  'promoCodeStatistics',
)

export function getPromoCodeStatisticsCollection(company: DocumentReference, startDate: Timestamp, endDate: Timestamp) {
  return getDocs(query(
    collectionPromoCodeStatistics,
    where(
      'company',
      '==',
      company,
    ),
    where(
      'date',
      '>=',
      startDate,
    ),
    where(
      'date',
      '<',
      endDate,
    ),
  ))
}
