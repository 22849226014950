import type { DocumentData, DocumentReference } from 'firebase/firestore'
import { collection, getDocs, limit, orderBy, query, startAfter, where } from 'firebase/firestore'
import { firestore } from '../firebaseCore'

export const collectionNews = collection(
  firestore,
  'news',
)

export function getNewsQuery(company: DocumentReference) {
  return getDocs(query(
    collectionNews,
    where(
      'company',
      '==',
      company,
    ),
    orderBy(
      'publishDay',
      'desc',
    ),
    limit(5),
  ))
}

export function getNextNews(company: DocumentReference, lastVisible: DocumentData) {
  return getDocs(query(
    collectionNews,
    where(
      'company',
      '==',
      company,
    ),
    orderBy(
      'publishDay',
      'desc',
    ),
    startAfter(lastVisible),
    limit(5),
  ))
}
