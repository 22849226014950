import type { DocumentReference } from 'firebase/firestore'
import { DocumentModel } from './DocumentModel'
import { ProfileModel } from './ProfileModel'

export class ModeratorModel extends DocumentModel {
  role: string | null
  profile: ProfileModel | null
  isActive: boolean

  constructor(data: Partial<ModeratorModel>, reference?: DocumentReference) {
    super(
      data,
      reference,
    )

    this.role = data.role || null

    this.profile = data.profile
      ? new ProfileModel(data.profile)
      : null

    this.isActive = data.isActive || false
  }

  toMap() {
    return {
      ...super.toMap(),

      role: this.role,
      profile: this.profile
        ? this.profile.toMap()
        : null,
      isActive: this.isActive,
    }
  }

  toMapCreate() {
    return {
      ...super.toMapCreate(),

      role: this.role,
      profile: this.profile
        ? this.profile.toMap()
        : null,
      isActive: this.isActive,
    }
  }
}
