import type { DocumentReference } from 'firebase/firestore'
import { serverTimestamp } from 'firebase/firestore'

export function onCreate(userDataReference: DocumentReference) {
  return {
    createdByUser: userDataReference,
    lastUpdateByUser: userDataReference,
    lastUpdateTime: serverTimestamp(),
  }
}

export function onUpdate(userDataReference: DocumentReference) {
  return {
    lastUpdateByUser: userDataReference,
    lastUpdateTime: serverTimestamp(),
  }
}
