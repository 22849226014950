import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { ref } from 'vue'
import type { DocumentReference } from 'firebase/firestore'
import { getDoc, getDocs, query, serverTimestamp, where, writeBatch } from 'firebase/firestore'
import { collectionUsers } from '../firestoreWrappers'
import store from '.'
import type { CustomerModel } from '../models/CustomerModel'
import { mapCustomers } from '../models/CustomerModel'
import { ROLE } from '../helpers/roles'
import type { SupplierModel } from '../models/SupplierModel'
import { mapSuppliers } from '../models/SupplierModel'
import { firestore } from '../firebaseCore'
import type { EmployeeModel } from '../models/EmployeeModel'
import type { SubsidyModel } from '~/models/SubsidyModel'

export const useManagerRoutesStore = defineStore('managerRoutes', () => {
  const employees: Ref<EmployeeModel[]> = ref([])
  const suppliers: Ref<SupplierModel[]> = ref([])
  const loading: Ref<boolean> = ref(false)
  const error: Ref<unknown | null> = ref(null)

  const resetState = () => {
    employees.value = []
    suppliers.value = []
    loading.value = false
    error.value = null
  }

  const getEmployees = (userDataRef: DocumentReference, suppliersList: DocumentReference[]) => {
    loading.value = true
    store.dispatch('shared/init')

    const employeesList: CustomerModel[] = []
    const uniqueSuppliers: CustomerModel[] = []

    const onMidSuccess = (docs) => {
      docs.docs.forEach((doc) => {
        const mappedDoc = mapCustomers(doc)
        if (!employeesList.map(employee => employee?.reference?.id).includes(mappedDoc?.reference?.id) && mappedDoc?.reference?.id !== userDataRef?.id) {
          employeesList.push(mappedDoc)
          // @ts-expect-error
          mappedDoc?.work?.suppliers?.forEach((supplier) => {
            // @ts-expect-error
            if (!uniqueSuppliers.map(uniqueSupplier => uniqueSupplier?.id).includes(supplier.id)) {
              uniqueSuppliers.push(supplier)
            }
          })
        }
      })
    }

    const onFailure = (errorArg) => {
      console.error(errorArg)
      error.value = errorArg
      loading.value = false
    }

    const promises = suppliersList.map(supplier =>
      getDocs(
        query(
          collectionUsers,
          where('work.suppliers', 'array-contains', supplier),
          where('role', '==', ROLE.MANAGER),
        ),
      ),
    )

    Promise.all(promises)
      .then((results) => {
        results.forEach(onMidSuccess)
        // @ts-expect-error
        const supplierPromises = uniqueSuppliers.map(uniqueSupplier => getDoc(uniqueSupplier))

        Promise.all(supplierPromises).then((supplierResults) => {
          // @ts-expect-error
          employees.value = employeesList
          suppliers.value = supplierResults.map(mapSuppliers(null))
          loading.value = false
        })
      })
      .catch(onFailure)
  }

  const updateSupplierSubsidy = (userDataRef: DocumentReference, reference: DocumentReference, subsidy: SubsidyModel, price: number) => {
    loading.value = true
    store.dispatch('shared/init')

    const batch = writeBatch(firestore)

    batch.update(reference, {
      subsidy: { ...subsidy, subsidyPrice: price },
      lastUpdateByUser: userDataRef,
      lastUpdateTime: serverTimestamp(),
    })

    batch.commit()
      .then(() => {
        loading.value = false
      })
      .catch((errorArg) => {
        console.error(errorArg)
        error.value = errorArg
        loading.value = false
      })
  }

  const updateCustomerSubsidy = (userDataRef: DocumentReference, reference: DocumentReference, price: number) => {
    loading.value = true
    store.dispatch('shared/init')

    const batch = writeBatch(firestore)

    batch.update(reference, {
      subsidyBudget: price,
      lastUpdateByUser: userDataRef,
      lastUpdateTime: serverTimestamp(),
    })

    batch.commit()
      .then(() => {
        loading.value = false
      })
      .catch((errorArg) => {
        console.error(errorArg)
        error.value = errorArg
        loading.value = false
      })
  }

  const updateActive = (userDataRef: DocumentReference, reference: DocumentReference, active: boolean) => {
    loading.value = true
    store.dispatch('shared/init')

    const batch = writeBatch(firestore)

    batch.update(reference, {
      isActive: active,
      lastUpdateByUser: userDataRef,
      lastUpdateTime: serverTimestamp(),
    })

    batch.commit()
      .then(() => {
        loading.value = false
      })
      .catch((errorArg) => {
        console.error(errorArg)
        error.value = errorArg
        loading.value = false
      })
  }

  return {
    employees,
    suppliers,
    loading,
    error,
    resetState,
    getEmployees,
    updateSupplierSubsidy,
    updateCustomerSubsidy,
    updateActive,
  }
})
