import { getDownloadURL, ref, uploadString } from 'firebase/storage'
import { generateRandomText } from './generateRandomText'
import { getIdFromRef } from './getIdFromRef'

export async function createAndUploadImage(companyRef, newRef, imgData, firebaseStorage, sufix = '') {
  const imagePath = `${getIdFromRef(companyRef)}/${getIdFromRef(newRef)}${generateRandomText() + sufix}`

  const uploadedData = await uploadString(
    ref(
      firebaseStorage,
      imagePath,
    ),
    imgData.dataUrl,
    'data_url',
  )

  const imageUrl = await getDownloadURL(uploadedData.ref)
  return {
    imageUrl,
    imagePath,
    uploadedData,
  }
}

export async function createAndUploadImageCompany(newRef, imgData, firebaseStorage) {
  const imagePath = `${getIdFromRef(newRef)}/logo${generateRandomText()}`

  const uploadedData = await uploadString(
    ref(
      firebaseStorage,
      imagePath,
    ),
    imgData.dataUrl,
    'data_url',
  )

  const imageUrl = await getDownloadURL(uploadedData.ref)
  return {
    imageUrl,
    imagePath,
    uploadedData,
  }
}
