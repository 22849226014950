import type { DocumentData, DocumentReference } from 'firebase/firestore'
import { collection, getDocs, onSnapshot, query, serverTimestamp, updateDoc, where } from 'firebase/firestore'
import { firestore } from '../firebaseCore'

export const collectionMessages = collection(
  firestore,
  'messages',
)

export const getMessagesQuery = () => getDocs(collectionMessages)

export function getVisibleToDoCateringOrdersQuery(onSuccess: (arg0: DocumentData) => any, onError: (error: unknown) => any) {
  return onSnapshot(
    query(
      collectionMessages,
      where(
        'category',
        '==',
        'catering-order',
      ),
      where(
        'isVisible',
        '==',
        true,
      ),
    ),
    onSuccess,
    onError,
  )
}

export function getCompanyCateringOrdersQuery(company: DocumentReference, onSuccess: (arg0: DocumentData) => any, onError: (error: unknown) => any) {
  return onSnapshot(
    query(
      collectionMessages,
      where(
        'company',
        '==',
        company,
      ),
    ),
    onSuccess,
    onError,
  )
}

export function updateMessage(itemRef: DocumentReference, status: string, userDataRef: DocumentReference) {
  return updateDoc(
    itemRef,
    {
      status,
      lastUpdateByUser: userDataRef,
      lastUpdateTime: serverTimestamp(),
    },
  )
}
