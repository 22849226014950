<script setup lang="ts">
import { useDrawerStore } from '~/store/drawer'

defineProps({
  dark: { type: Boolean }
})

const drawerStore = useDrawerStore()

function toggleDrawer() {
  drawerStore.toggleDrawerLanding()
}
</script>

<template>
  <v-app-bar-nav-icon
    class="d-print-none"
    :dark="dark"
    @click="toggleDrawer"
  />
</template>
