import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import company from './company'
import shared from './shared'
import snackbar from './snackbar'

import { regiesterRest } from './restStores'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    app,
    company,
    shared,
    snackbar,
  },
})

regiesterRest(store)

export default store
