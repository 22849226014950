import type { DocumentData, DocumentReference } from 'firebase/firestore'
import { Timestamp } from 'firebase/firestore'
import type { AddressModel } from './AddressModel'
import type { ProductModel } from './ProductModel'

export interface IOrder {
  customerName: string
  date: Timestamp
  orderDate: Timestamp
  orderNumber: number
  progress: string
  supplier: DocumentReference
  user: DocumentReference
  isCompleted: boolean
  completementStatus: string
  isOfficeOrder: boolean
  deliver: DocumentReference | null
  route: DocumentReference | null
  deliveryTime: string
  deliveryTimeTo: string
  deliveryTimeFrom: string
  isPayment: boolean
  paymentMethod: string
  paymentStatus: string
  paymentId: any
  phone: string
  subsidyIdentifier: string
  subsidyAmount: number | null
  isNewUser: boolean
  paperBagCount: number
  paperBagPrice: number
  cutleryCount: number
  cutleryPrice: number
  deliveryCost: number
  orderStatus: string
  invoice: any
  isSales: boolean
  coupon: string
  salesPercentage: number
  isLoyaltyProgram: boolean
  fixedSalesAmount: number
  orders: ProductModel[]
  commentToOrder: string
  address: AddressModel | null
  supplierCity: string
  source: string
  communicationMethod: string
  isPickUp: boolean
  isPublic: boolean
  loading?: boolean

  lastUpdateTime: Timestamp
  lastUpdateByUser: DocumentReference
  createdByUser: DocumentReference
  reference: DocumentReference | null
}

export default class OrderModel implements IOrder {
  customerName: string
  date: Timestamp
  orderDate: Timestamp
  orderNumber: number
  progress: string
  supplier: DocumentReference
  user: DocumentReference
  isCompleted: boolean
  completementStatus: string
  isOfficeOrder: boolean
  deliver: DocumentReference | null
  route: DocumentReference | null
  deliveryTime: string
  deliveryTimeTo: string
  deliveryTimeFrom: string
  isPayment: boolean
  paymentMethod: string
  paymentStatus: string
  paymentId: any
  phone: string
  subsidyIdentifier: string
  subsidyAmount: number | null
  isNewUser: boolean
  paperBagCount: number
  paperBagPrice: number
  cutleryCount: number
  cutleryPrice: number
  deliveryCost: number
  orderStatus: string
  invoice: any
  isSales: boolean
  coupon: string
  salesPercentage: number
  isLoyaltyProgram: boolean
  fixedSalesAmount: number
  orders: ProductModel[]
  commentToOrder: string
  address: AddressModel | null
  supplierCity: string
  source: string
  communicationMethod: string
  isPickUp: boolean
  isPublic: boolean
  loading?: boolean

  lastUpdateTime: Timestamp
  lastUpdateByUser: DocumentReference
  createdByUser: DocumentReference
  reference: DocumentReference | null

  constructor(data: IOrder, reference?: DocumentReference) {
    this.customerName = data.customerName || ''
    this.date = data.date || new Timestamp(Math.floor(new Date().getTime() / 1000), 0)
    this.orderDate = data.orderDate || new Timestamp(Math.floor(new Date().getTime() / 1000), 0)
    this.orderNumber = data.orderNumber || 0
    this.progress = data.progress || ''
    this.supplier = data.supplier || null
    this.user = data.user || null
    this.isCompleted = data.isCompleted || false
    this.completementStatus = data.completementStatus || ''
    this.isOfficeOrder = data.isOfficeOrder || false
    this.deliver = data.deliver || null
    this.route = data.route || null
    this.deliveryTime = data.deliveryTime || ''
    this.deliveryTimeTo = data.deliveryTimeTo || ''
    this.deliveryTimeFrom = data.deliveryTimeFrom || ''
    this.isPayment = data.isPayment || false
    this.paymentMethod = data.paymentMethod || ''
    this.paymentStatus = data.paymentStatus || ''
    this.paymentId = data.paymentId || {}
    this.phone = data.phone || ''
    this.subsidyIdentifier = data.subsidyIdentifier || ''
    this.subsidyAmount = data.subsidyAmount
    this.isNewUser = data.isNewUser || false
    this.paperBagCount = data.paperBagCount || 0
    this.paperBagPrice = data.paperBagPrice || 0
    this.cutleryCount = data.cutleryCount || 0
    this.cutleryPrice = data.cutleryPrice || 0
    this.deliveryCost = data.deliveryCost || 0
    this.orderStatus = data.orderStatus || ''
    this.invoice = data.invoice || null
    this.isSales = data.isSales || false
    this.coupon = data.coupon || ''
    this.salesPercentage = data.salesPercentage || 0
    this.isLoyaltyProgram = data.isLoyaltyProgram || false
    this.fixedSalesAmount = Number(data.fixedSalesAmount) || 0
    this.orders = data.orders || []
    this.commentToOrder = data.commentToOrder || ''
    this.address = data.address || null
    this.supplierCity = data.supplierCity || ''
    this.source = data.source || ''
    this.communicationMethod = data.communicationMethod || ''
    this.isPickUp = data.isPickUp || false
    this.isPublic = data.isPublic || false
    this.loading = false

    this.createdByUser = data.createdByUser || null
    this.lastUpdateByUser = data.lastUpdateByUser || null
    this.lastUpdateTime = data.lastUpdateTime || null
    this.reference = reference || null
  }

  toMap() {
    return {
      customerName: this.customerName,
      date: this.date,
      orderDate: this.orderDate,
      orderNumber: this.orderNumber,
      progress: this.progress,
      supplier: this.supplier,
      user: this.user,
      isCompleted: this.isCompleted,
      completementStatus: this.completementStatus,
      isOfficeOrder: this.isOfficeOrder,
      deliver: this.deliver,
      route: this.route,
      deliveryTime: this.deliveryTime,
      deliveryTimeTo: this.deliveryTimeTo,
      deliveryTimeFrom: this.deliveryTimeFrom,
      isPayment: this.isPayment,
      paymentMethod: this.paymentMethod,
      paymentStatus: this.paymentStatus,
      paymentId: this.paymentId,
      phone: this.phone,
      subsidyIdentifier: this.subsidyIdentifier,
      subsidyAmount: this.subsidyAmount,
      isNewUser: this.isNewUser,
      paperBagCount: this.paperBagCount,
      paperBagPrice: this.paperBagPrice,
      cutleryCount: this.cutleryCount,
      cutleryPrice: this.cutleryPrice,
      deliveryCost: this.deliveryCost,
      orderStatus: this.orderStatus,
      invoice: this.invoice,
      isSales: this.isSales,
      coupon: this.coupon?.trim() || '',
      salesPercentage: this.salesPercentage,
      isLoyaltyProgram: this.isLoyaltyProgram,
      fixedSalesAmount: this.fixedSalesAmount,
      orders: this.orders?.map(order => order.toMap()) || [],
      commentToOrder: this.commentToOrder,
      address: this.address?.toMap() || null,
      supplierCity: this.supplierCity,
      source: this.source,
      communicationMethod: this.commentToOrder,
      isPickUp: this.isPickUp,
      isPublic: this.isPublic,
      loading: this.loading,

      lastUpdateTime: this.lastUpdateTime,
      lastUpdateByUser: this.lastUpdateByUser,
      createdByUser: this.createdByUser,
    } as IOrder
  }
}

export function mapOrder(order: DocumentData) {
  return new OrderModel(
    order.data(),
    order.reference,
  )
}
