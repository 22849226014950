import { computed, getCurrentInstance } from 'vue'

export function useI18n() {
  // @ts-expect-error getCurrentInstance
  const { $root } = getCurrentInstance().proxy
  const { $i18n } = $root

  const setLocale = (locale: string) => {
    $i18n.locale = locale
  }

  const getLocale = computed(() => {
    return $i18n.locale
  })

  const isPL = computed(() => {
    return $i18n.locale === 'pl'
  })

  return {
    $i18n,
    getLocale,
    isPL,
    setLocale,
  }
}
