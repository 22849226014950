export class Earnings {
  downPercentage: number
  upPercentage: number

  constructor(data: Partial<Earnings>) {
    this.downPercentage = data.downPercentage
      ? Number(data.downPercentage)
      : 0
    this.upPercentage = data.upPercentage
      ? Number(data.upPercentage)
      : 0
  }

  toMap() {
    return {
      downPercentage: this.downPercentage,
      upPercentage: this.upPercentage,
    }
  }
}
