import type { DocumentData, DocumentReference } from 'firebase/firestore'
import { OnlineTransactionReports } from './OnlineTransactionReportsModel'
import { OrdersInfoModel } from './OrdersInfoModel'

export class OrdersStatistic {
  city: string | null
  company: DocumentReference | null
  date: string | null
  homeOrders: OrdersInfoModel | null
  officeOrders: OrdersInfoModel | null
  onlineTransactionReports: OnlineTransactionReports | null
  soldOutDishes: string | null
  notSoldOutDishes: string | null
  reference: DocumentReference | null

  constructor(data: Partial<OrdersStatistic>, reference?: DocumentReference | null) {
    this.city = data.city || null
    this.company = data.company || null
    this.date = data.date || null
    this.homeOrders = data?.homeOrders
      ? new OrdersInfoModel(data?.homeOrders)
      : null
    this.officeOrders = data?.officeOrders
      ? new OrdersInfoModel(data?.officeOrders)
      : null

    this.onlineTransactionReports = data?.onlineTransactionReports
      ? new OnlineTransactionReports(data.onlineTransactionReports)
      : null
    // @ts-expect-error todo
    this.soldOutDishes = data.soldOutDishes
    // @ts-expect-error todo
    this.notSoldOutDishes = data.notSoldOutDishes

    this.reference = reference || null
  }

  toMap() {
    return {
      city: this.city,
      company: this.company,
      date: this.date,
      homeOrders: this.homeOrders
        ? this.homeOrders.toMap()
        : null,
      officeOrders: this.officeOrders
        ? this.officeOrders.toMap()
        : null,
      onlineTransactionReports: this.onlineTransactionReports,
      soldOutDishes: this.soldOutDishes,
      notSoldOutDishes: this.notSoldOutDishes,
    }
  }
}

export function mapOrdersStatistic(data: DocumentData) {
  return new OrdersStatistic(
    data.data(),
    data.ref,
  )
}
