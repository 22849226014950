<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted } from 'vue'
import { useVuex } from '../../useVuex'
import i18n from '~/i18n'

const { $store } = useVuex()

const visible = computed(() => $store.state.snackbar.visible)
const color = computed(() => $store.state.snackbar.color)
const timeout = computed(() => $store.state.snackbar.timeout)
const text = computed(() => $store.state.snackbar.text)

onMounted(() => {
  const hideSnackbar = () => {
    $store.dispatch('snackbar/hideSnackbar')
  }

  const showSnackbarInfinity = () => {
    $store.dispatch(
      'snackbar/showSnackbarInfinity',
      {
        color: 'primary',
        text: i18n.t('OfflineMode.offline'),
      },
    )
  }

  window.addEventListener('online', hideSnackbar)
  window.addEventListener('offline', showSnackbarInfinity)
})

onBeforeUnmount(() => {
  window.removeEventListener('online', () => { })
  window.removeEventListener('offline', () => { })
})
</script>

<template>
  <v-snackbar
    :color="color || 'primary'"
    :timeout="timeout || 600"
    :value="visible"
  >
    <v-row class="justify-center">
      <span class="text-h6">
        {{ text }}
      </span>
    </v-row>
  </v-snackbar>
</template>
