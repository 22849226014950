import type { DocumentData, DocumentReference } from 'firebase/firestore'
import { EmployeeModel } from './EmployeeModel'
import type OrderModel from './OrderModel'

export class OwnerModel extends EmployeeModel {
  isOwner: string | null
  hidden: boolean | null

  constructor(data: Partial<OrderModel>, reference?: DocumentReference) {
    super(
      // @ts-expect-error todo
      data,
      reference,
    )
    // @ts-expect-error todo
    this.isOwner = data.isOwner || false
    // @ts-expect-error todo
    this.hidden = data.hidden || false
  }

  toMap() {
    return {
      ...super.toMap(),
      isOwner: this.isOwner,
      hidden: this.hidden || false,
    }
  }

  toMapCreate() {
    return {
      ...super.toMapCreate(),
      isOwner: this.isOwner,
      hidden: this.hidden || false,
    }
  }
}

export class ManagerModel extends EmployeeModel {}

export function mapManagers(manager: DocumentData) {
  return new ManagerModel(
    manager.data(),
    manager.ref,
  )
}
