import EmployeesModule from './employees'
import MenusModule from './menus'
import OrdersModule from './orders'
import SuppliersModule from './suppliers'

export function regiesterRest(store) {
  store.registerModule(
    'employees',
    EmployeesModule,
  )
  store.registerModule(
    'menus',
    MenusModule,
  )

  store.registerModule(
    'orders',
    OrdersModule,
  )
  store.registerModule(
    'suppliers',
    SuppliersModule,
  )
}
