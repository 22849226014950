import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { ref } from 'vue'
import type { DocumentData, DocumentReference, Timestamp } from 'firebase/firestore'
import { getDocs } from 'firebase/firestore'
import { errorDefault } from '../helpers/snackbar'
import { getCollectionOrdersStatistic } from '../firestoreWrappers'
import { mapOrdersStatistic } from '../models/OrdersStatisticModel2'
import store from '~/store'

export const useOrdersStatisticStore = defineStore('ordersStatistic', () => {
  const data: Ref< any | null> = ref(null)
  const loading: Ref<boolean> = ref(false)
  const error: Ref<unknown | null> = ref(null)

  function resetState() {
    data.value = null
    loading.value = false
    error.value = null
  }

  function getOrdersStatistic(company: DocumentReference, startDate: Timestamp, endDate: Timestamp, date: string, city: string) {
    loading.value = true
    error.value = null

    const onSuccess = (arg0: DocumentData) => {
      data.value = {
        ...data.value,
        [city]: {
          ...data.value?.[city],
          [date]: arg0.docs.map(mapOrdersStatistic),
        },
      }
      loading.value = false
    }

    const onError = (err: unknown) => {
      console.error(err)
      error.value = err
      loading.value = false
      store.dispatch('snackbar/showSnackbar', errorDefault(err))
    }

    getDocs(getCollectionOrdersStatistic(company, startDate, endDate, city))
      .then(onSuccess)
      .catch(onError)
  }

  return {
    data,
    loading,
    error,
    resetState,
    getOrdersStatistic,
  }
})
