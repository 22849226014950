import type { DocumentReference } from 'firebase/firestore'
import { DocumentModel } from './DocumentModel'
import { ProfileModel } from './ProfileModel'

export class EmployeeModel extends DocumentModel {
  company: DocumentReference | null
  role: string | null
  profile: ProfileModel | null
  isActive: boolean
  isVisibleEarns: any

  constructor(data: Partial<EmployeeModel>, reference?: DocumentReference) {
    super(
      data,
      reference,
    )
    this.company = data.company || null
    this.role = data.role || null
    this.isVisibleEarns = data.isVisibleEarns || false
    this.profile = data.profile
      ? new ProfileModel(data.profile)
      : null
    this.isActive = data.isActive || false
  }

  toMap() {
    return {
      ...super.toMap(),

      isVisibleEarns: this.isVisibleEarns || false,
      company: this.company,
      role: this.role,
      profile: this.profile
        ? this.profile.toMap()
        : null,
      isActive: this.isActive,
    }
  }

  toMapCreate() {
    return {
      ...super.toMapCreate(),

      isVisibleEarns: this.isVisibleEarns || false,
      company: this.company,
      role: this.role,
      profile: this.profile
        ? this.profile.toMap()
        : null,
      isActive: this.isActive,
    }
  }
}
