import { defineStore } from 'pinia'
import type { DocumentData, DocumentReference } from 'firebase/firestore'
import { addDoc, deleteDoc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { errorDefault, savedDefault } from '../helpers/snackbar'
import { getIdFromRef } from '../helpers/getIdFromRef'
import type { InvoiceModel } from '../models/InvoiceModel'
import { mapData } from '../models/InvoiceModel'
import { collectionInvoices, getInvoicesCollection } from '../firebase/firestoreInvoices'
import store from '.'
import type { Ref } from 'vue'
import { ref } from 'vue'

function onErrorShared(error: unknown) {
  console.error(error)
  store.dispatch(
    'snackbar/showSnackbar',
    errorDefault(error),
  )
}

export const useInvoicesStore = defineStore('invoices', () => {
  const data: Ref<InvoiceModel[] | null> = ref(null)
  const loading: Ref<boolean> = ref(false)
  const error: Ref<unknown | null> = ref(null)

  const resetState = () => {
    data.value = null
    loading.value = false
    error.value = null
  }

  const getInvoices = (userDataCompany: DocumentReference) => {
    loading.value = true
    store.dispatch('shared/init')

    getInvoicesCollection(userDataCompany)
      .then((arg0: DocumentData) => {
        data.value = arg0.docs.map(mapData)
        store.dispatch('shared/success')
        loading.value = false
      })
      .catch((err: unknown) => {
        console.error(err)
        error.value = err
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(err),
        )
        loading.value = false
      })
  }

  const createInvoice = (userDataRef: DocumentReference, invoiceData: InvoiceModel) => {
    loading.value = true

    invoiceData.lastUpdateByUser = userDataRef
    invoiceData.lastUpdateTime = serverTimestamp()

    addDoc(collectionInvoices, {
      ...invoiceData.toMap(),
      createdByUser: userDataRef,
    })
      .then((newInvoiceRef) => {
        invoiceData.reference = newInvoiceRef
        data.value = [...(data.value || []), invoiceData]
        store.dispatch(
          'snackbar/showSnackbar',
          savedDefault,
        )
        loading.value = false
      })
      .catch((err: unknown) => {
        if (invoiceData.reference) {
          deleteDoc(invoiceData.reference)
        }
        console.error(err)
        error.value = err
        onErrorShared(err)
        loading.value = false
      })
  }

  const editInvoice = (userDataRef: DocumentReference, newData: InvoiceModel) => {
    loading.value = true

    newData.lastUpdateByUser = userDataRef
    newData.lastUpdateTime = serverTimestamp()

    updateDoc(newData.reference as DocumentReference, newData.toMap())
      .then(() => {
        data.value = data.value?.map((invoice) => {
          if (getIdFromRef(invoice.reference) === getIdFromRef(newData.reference)) {
            return newData
          }
          return invoice
        }) || []
        store.dispatch(
          'snackbar/showSnackbar',
          savedDefault,
        )
        loading.value = false
      })
      .catch((err: unknown) => {
        console.error(err)
        error.value = err
        onErrorShared(err)
        loading.value = false
      })
  }

  return {
    data,
    loading,
    error,
    resetState,
    getInvoices,
    createInvoice,
    editInvoice,
  }
})
