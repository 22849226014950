import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { ref } from 'vue'
import { errorDefault } from '../helpers/snackbar'
import type { PromoCodeStatistic } from '../models/PromoCodeStatisticModel'
import { mapPromoCodeStatistic } from '../models/PromoCodeStatisticModel'
import { getPromoCodeStatisticsCollection } from '../firebase/firestorePromoCodeStatistics'
import store from '.'
import type { DocumentData, DocumentReference, Timestamp } from 'firebase/firestore'

function onErrorShared(error: unknown) {
  console.error(error)
  store.dispatch(
    'snackbar/showSnackbar',
    errorDefault(error),
  )
}

export const usePromoCodeStatisticsStore = defineStore('promoCodeStatistics', () => {
  const data: Ref<PromoCodeStatistic[] | null> = ref(null)
  const loading: Ref<boolean> = ref(false)
  const error: Ref<unknown | null> = ref(null)
  const size: Ref<number | null> = ref(null)
  const lastVisible: Ref<DocumentData | null> = ref(null)

  const resetState = () => {
    data.value = null
    loading.value = false
    error.value = null
    size.value = null
    lastVisible.value = null
  }

  const getPromoCodeStatistic = (userDataCompany: DocumentReference, startDate: Timestamp, endDate: Timestamp) => {
    loading.value = true
    error.value = null

    getPromoCodeStatisticsCollection(userDataCompany, startDate, endDate)
      .then(({ docs, size: newSize }) => {
        size.value = newSize
        loading.value = false
        error.value = null
        if (data.value) {
          data.value = [...data.value, ...docs.map(mapPromoCodeStatistic)]
        }
        else {
          data.value = docs.map(mapPromoCodeStatistic)
        }
      })
      .catch((errorArg: unknown) => {
        loading.value = false
        error.value = errorArg
        onErrorShared(errorArg)
      })
  }

  return {
    data,
    loading,
    error,
    size,
    lastVisible,
    resetState,
    getPromoCodeStatistic,
  }
})
