import { addDoc, deleteDoc, doc, getDoc, serverTimestamp, setDoc, updateDoc, writeBatch } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes, uploadString } from 'firebase/storage'
import { errorDefault, savedDefault } from '../helpers/snackbar'
import {
  collectionCompanies,
  collectionMenuCategories,
  collectionSMS,
  collectionStatisticAccess,
} from '../firestoreWrappers'
import { ROLE } from '../helpers/roles'
import { USER_PREFERENCES } from '../helpers/statisticAccess'
import { createAndUploadImageCompany } from '../helpers/uploadImage'
import { createSMSDate } from '../helpers/time'
import { firestore, storage } from '../firebaseCore'
import { getIdFromRef } from '../helpers/getIdFromRef'
import { AGREEMENT_STATUS } from '../models/AgreementModel'
import store from '.'
import { mapCompany } from '~/models/CompanyModel'

function initialState() {
  return {
    company: null,
    createError: null,
    createLoading: false,
    fetchError: null,
    fetchLoading: false,

    agreement: null,
    agreementNew: null,
    agreementLoading: null,
    agreementError: null,
    acceptAgreementLoading: false,
    acceptAgreementError: false,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    getCompany({ commit }, { userData }) {
      commit('GET_COMPANY_REQUEST')

      const onSuccess = (company) => {
        commit(
          'GET_COMPANY_SUCCESS',
          mapCompany(company),
        )
      }

      const onError = (error: unknown) => {
        console.error(error)
        commit(
          'GET_COMPANY_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      getDoc(userData.company).then(onSuccess).catch(onError)
    },
    setCompany({ commit }, company) {
      commit(
        'GET_COMPANY_SUCCESS',
        mapCompany(company),
      )
    },
    uploadAgreement({ commit, dispatch, 'state': { company } }, { selectedFile, userData }) {
      commit('UPLOAD_AGREEMENT_REQUEST')
      const activeFile = `${new Date().toISOString()}${selectedFile.name}`

      const onSuccessFinish = agreement => () => {
        commit(
          'UPLOAD_AGREEMENT_SUCCESS',
          agreement,
        )
      }

      const onError = (error: unknown) => {
        console.error(error)
        commit(
          'UPLOAD_AGREEMENT_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      const onSuccess = () => {
        dispatch('getCompany', userData)
          .then(() => {
            const acceptedByCopy = company?.agreement?.acceptedBy
            acceptedByCopy[getIdFromRef(userData.reference)] = true
            const agreement = {
              acceptedBy: acceptedByCopy,
              activeFile,
              status: AGREEMENT_STATUS.waitingModeratorAccept,
            }

            updateDoc(
              userData.company,
              {
                agreement,
                lastUpdateByUser: userData.reference,
                lastUpdateTime: serverTimestamp(),
              },
            )
              .then(onSuccessFinish(agreement))
              .catch(onError)
          })
          .catch(onError)
      }

      const path = `${getIdFromRef(userData.company)}/docs/${activeFile}`

      uploadBytes(
        ref(
          storage,
          path,
        ),
        selectedFile,
      )
        .then(onSuccess)
        .catch(onError)
    },
    getCompanyAgreement({ commit, 'state': { company } }, { userData }) {
      commit('GET_COMPANY_AGREEMENT_REQUEST')

      const onSuccess = (url) => {
        commit(
          'GET_COMPANY_AGREEMENT_SUCCESS',
          url,
        )
      }

      const onError = (error: unknown) => {
        console.error(error)
        commit(
          'GET_COMPANY_AGREEMENT_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }
      const path = `${getIdFromRef(userData.company)}/docs/${
        company.agreement.activeFile
      }`

      getDownloadURL(ref(
        storage,
        path,
      ))
        .then(onSuccess)
        .catch(onError)
    },
    async createCompany(
      {
        commit,
      },
      { company, userData },
    ) {
      commit('CREATE_COMPANY_REQUEST')

      let companyRef
      let uploaded

      const onError = (error: unknown) => {
        console.error(error)
        if (companyRef)
          deleteDoc(companyRef).then(() => {})

        if (uploaded && uploaded?.ref)
          deleteDoc(uploaded.ref).then(() => {})

        commit(
          'CREATE_COMPANY_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      try {
        company.lastUpdateByUser = userData.reference
        company.lastUpdateTime = serverTimestamp()

        const imgData = company.logo
        company.logo = null
        companyRef = await addDoc(
          collectionCompanies,
          {
            ...company.toMap(),

            createdByUser: userData.reference,
          },
        )

        if (imgData) {
          const { imageUrl, imagePath, uploadedData } = await createAndUploadImageCompany(
            companyRef,
            imgData,
            storage,
          )

          company.logo = imageUrl
          company.logoPath = imagePath
          uploaded = uploadedData
        }
        company.reference = companyRef

        await updateDoc(
          userData.reference,
          {
            company: companyRef,

            lastUpdateByUser: userData.reference,
            lastUpdateTime: serverTimestamp(),
          },
        )

        const batch = writeBatch(firestore)

        batch.update(
          companyRef,
          {
            logo: company.logo,
            logoPath: company.logoPath,

            lastUpdateByUser: userData.reference,
            lastUpdateTime: serverTimestamp(),
          },
        )

        let menuCategoriesRef
        let statisticAccessRef
        let smsReference

        try {
          const companyID = getIdFromRef(companyRef)

          menuCategoriesRef = doc(
            collectionMenuCategories,
            companyID,
          )
          setDoc(
            menuCategoriesRef,
            {
              menuCategories: [],

              createdByUser: userData.reference,
              lastUpdateByUser: userData.reference,
              lastUpdateTime: serverTimestamp(),
            },
          )

          statisticAccessRef = doc(
            collectionStatisticAccess,
            companyID,
          )
          setDoc(
            statisticAccessRef,
            {
              access: [
                {
                  role: ROLE.DELIVER,
                  [USER_PREFERENCES]: false,
                },
                {
                  role: ROLE.GET_DELIVER,
                  [USER_PREFERENCES]: false,
                },
                {
                  role: ROLE.MANAGER,
                  [USER_PREFERENCES]: false,
                },
              ],

              createdByUser: userData.reference,
              lastUpdateByUser: userData.reference,
              lastUpdateTime: serverTimestamp(),
            },
          )

          const smsCollection = {
            lastUpdateByUser: userData.reference,
            lastUpdateTime: serverTimestamp(),
          }

          smsCollection[createSMSDate()] = 0
          smsCollection.lastUpdateByUser = userData.reference
          smsCollection.lastUpdateTime = serverTimestamp()

          smsReference = doc(
            collectionSMS,
            companyID,
          )
          setDoc(
            smsReference,
            smsCollection,
          )

          await batch.commit()

          commit(
            'CREATE_COMPANY_SUCCESS',
            company,
          )
          store.dispatch(
            'app/updateUserData',
            {
              ...userData,
              company: companyRef,
            },
          )
          store.dispatch(
            'snackbar/showSnackbar',
            savedDefault,
          )
        }
        catch (error: unknown) {
          const promises = [deleteDoc(uploaded.ref)]
          if (menuCategoriesRef)
            promises.push(deleteDoc(menuCategoriesRef))

          if (statisticAccessRef)
            promises.push(deleteDoc(statisticAccessRef))

          if (smsReference)
            promises.push(deleteDoc(smsReference))

          Promise.all(promises).finally(() => {
            commit(
              'CREATE_COMPANY_FAILURE',
              error,
            )
            console.error(error)
            store.dispatch(
              'snackbar/showSnackbar',
              errorDefault(error),
            )
          })
        }
      }
      catch (error: unknown) {
        onError(error)
      }
    },
    editCompany({ commit }, { company, userData }) {
      commit('CREATE_COMPANY_REQUEST')

      const onSuccess = () => {
        commit(
          'CREATE_COMPANY_SUCCESS',
          company,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          savedDefault,
        )
      }
      const onError = (error: unknown) => {
        console.error(error)
        commit(
          'CREATE_COMPANY_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      const updateCompany = (companyData) => {
        updateDoc(
          companyData.reference,
          {
            ...companyData.toMap(),
            lastUpdateByUser: userData.reference,
            lastUpdateTime: serverTimestamp(),
          },
        ).then(onSuccess).catch(onError)
      }

      const onUploadedImage = (imageUrl) => {
        company.logo = imageUrl
        updateCompany(company)
      }
      const onSuccessAddImage = (imageRef) => {
        getDownloadURL(imageRef.ref).then(onUploadedImage)
      }

      if (typeof company.logo === 'string') {
        updateCompany(company)
      }
      else {
        uploadString(
          ref(
            storage,
            company.logoPath,
          ),
          company.logo.dataUrl,
          'data_url',
        )
          .then(onSuccessAddImage)
          .catch(onError)
      }
    },
  },
  mutations: {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    GET_COMPANY_REQUEST(_state) {
      _state.fetchError = null
      _state.fetchLoading = true
    },
    GET_COMPANY_SUCCESS(_state, company) {
      _state.fetchLoading = false
      _state.company = company
    },
    GET_COMPANY_FAILURE(_state, error) {
      _state.fetchLoading = false
      _state.fetchError = error
    },

    GET_COMPANY_AGREEMENT_REQUEST(_state) {
      _state.agreementError = null
      _state.agreementLoading = true
    },
    GET_COMPANY_AGREEMENT_SUCCESS(_state, agreement) {
      _state.agreementLoading = false
      _state.agreement = agreement
    },
    GET_COMPANY_AGREEMENT_FAILURE(_state, error) {
      _state.agreementLoading = false
      _state.agreementError = error
    },

    CREATE_COMPANY_REQUEST(_state) {
      _state.createLoading = true
      _state.createError = null
    },
    CREATE_COMPANY_SUCCESS(_state, company) {
      _state.createLoading = false
      _state.company = company
      _state.createError = null
    },
    CREATE_COMPANY_FAILURE(_state, error) {
      _state.createLoading = false
      _state.createError = error
    },

    UPLOAD_AGREEMENT_REQUEST(_state) {
      _state.agreementLoading = true
      _state.agreementError = null
    },
    UPLOAD_AGREEMENT_SUCCESS(_state, agreement) {
      _state.agreementLoading = false
      _state.company = {
        ..._state.company,
        agreement: {
          ..._state.company?.agreement || {},
          ...agreement,
        },
      }
      _state.agreementError = null
    },
    UPLOAD_AGREEMENT_FAILURE(_state, error) {
      _state.agreementLoading = false
      _state.agreementError = error
    },
  },
}
