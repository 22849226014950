import type { DocumentReference } from 'firebase/firestore'

export class OnlineTransactionReports {
  city: string | null
  company: DocumentReference | null
  date: string | null
  failedTransactions: number | null
  failedTransactionsCount: number | null
  partialRefundTransactions: number | null
  partialRefundTransactionsCount: number | null
  refundTransactions: number | null
  refundTransactionsCount: number | null
  successTransactions: number | null
  successTransactionsCount: number | null
  waitingTransactions: number | null
  waitingTransactionsCount: number | null

  constructor(data: Partial<OnlineTransactionReports>) {
    this.city = data.city || null
    this.company = data.company || null
    this.date = data.date || null
    this.failedTransactions = data.failedTransactions || 0
    this.failedTransactionsCount = data.failedTransactionsCount || 0
    this.partialRefundTransactions = data.partialRefundTransactions || 0
    this.partialRefundTransactionsCount = data.partialRefundTransactionsCount || 0
    this.refundTransactions = data.refundTransactions || 0
    this.refundTransactionsCount = data.refundTransactionsCount || 0
    this.successTransactions = data.successTransactions || 0
    this.successTransactionsCount = data.successTransactionsCount || 0
    this.waitingTransactions = data.waitingTransactions || 0
    this.waitingTransactionsCount = data.waitingTransactionsCount || 0
  }

  toMap() {
    return {
      city: this.city,
      company: this.company,
      date: this.date,
      failedTransactions: this.failedTransactions,
      failedTransactionsCount: this.failedTransactionsCount,
      partialRefundTransactions: this.partialRefundTransactions,
      partialRefundTransactionsCount: this.partialRefundTransactionsCount,
      refundTransactions: this.refundTransactions,
      refundTransactionsCount: this.refundTransactionsCount,
      successTransactions: this.successTransactions,
      successTransactionsCount: this.successTransactionsCount,
      waitingTransactions: this.waitingTransactions,
      waitingTransactionsCount: this.waitingTransactionsCount,
    }
  }
}
