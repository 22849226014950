const threeDots = '...'

export function truncate(input: string, length = 40) {
  return input?.length > length
    ? input.substring(
      0,
      length,
    ) + threeDots
    : input
}

export function phoneNumberFormat(phone: string) {
  return `${phone.substring(
    0,
    3,
  )} ${phone.substring(
    3,
    6,
  )} ${phone.substring(
    6,
    9,
  )} ${phone.substring(9)}`
}

const polishLetters = [
  'ą',
  'ć',
  'ę',
  'ł',
  'ń',
  'ó',
  'ś',
  'ź',
  'ż',
]
const normalLetters = [
  'a',
  'c',
  'e',
  'l',
  'n',
  'o',
  's',
  'z',
  'z',
]
export function removePolishLetters(tag: string) {
  let tmp = tag
  for (let index = 0; index < polishLetters.length; index++) {
    tmp = tmp.replaceAll(
      polishLetters[index],
      normalLetters[index],
    )
  }
  return tmp
}
