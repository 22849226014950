import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { ref } from 'vue'
import type { DocumentData, DocumentReference, Timestamp } from 'firebase/firestore'
import { getDocs } from 'firebase/firestore'
import { errorDefault } from '../helpers/snackbar'
import {
  getCollectionOrdersStatistic,
} from '../firestoreWrappers'
import { mapOrdersStatistic } from '../models/OrdersStatisticModel'
import store from '~/store'

export const useOnlineTransactionReportsStore = defineStore('onlineTransactionReports', () => {
  const data: Ref<Record<string, any> | null> = ref(null)
  const loading: Ref<boolean> = ref(false)
  const error: Ref<unknown | null> = ref(null)

  const resetState = () => {
    data.value = null
    loading.value = false
    error.value = null
  }

  const getOnlineTransactionReports = (companyRef: DocumentReference, startDate: Timestamp, endDate: Timestamp, date: string, city: string) => {
    loading.value = true
    error.value = null

    getDocs(getCollectionOrdersStatistic(companyRef, startDate, endDate, city))
      .then((arg0: DocumentData) => {
        const copy = { ...(data.value || {}) }
        copy[city] = data.value?.[city] || {}
        copy[city][date] = arg0.docs.map(mapOrdersStatistic)

        data.value = copy
        error.value = null
        loading.value = false
      })
      .catch((errorArg: unknown) => {
        error.value = errorArg
        loading.value = false
        store.dispatch('snackbar/showSnackbar', errorDefault(errorArg))
      })
  }

  return {
    data,
    loading,
    error,
    resetState,
    getOnlineTransactionReports,
  }
})
